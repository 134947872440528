@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.footer {
  background-color: var.$color-neutral-background-medium;
  color: var.$color-neutral-text-weak;
  padding-top: fn.spacing(4);
}

.footer-content-container {
  max-width: var.$maximum-site-content-width;
  padding-left: var.$default-gutter-width;
  padding-right: var.$default-gutter-width;

  &[data-narrow='true'] {
    max-width: var.$maximum-site-content-width-narrow;
  }
}

.footer a {
  color: var.$color-neutral-text-onlight;
}

.footer-address {
  margin-top: fn.spacing(6); // 48px
}

.footer-phone {
  margin-top: fn.spacing(2); // 16px
}

.footer-row {
  display: flex;
  flex-direction: column;
}

.footer-logo {
  display: block;
  height: fn.spacing(6.375); // 51px
  position: relative;
  width: fn.spacing(8); // 64px
}

.footer-brand-icon-row {
  margin-bottom: fn.spacing(6); // 48px
}

.footer-section + .footer-section {
  margin-top: fn.spacing(4); // 32px
}

.footer-section:first-child {
  max-width: none;
}

.footer-section ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.footer-section li {
  margin-top: fn.spacing(2); // 16px

  // override Sunbeam's default Link color
  a {
    color: var.$color-neutral-icon-onlight;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.footer-section h4 {
  color: var.$color-neutral-grey-0;
}

.footer-disclosure {
  color: var.$color-neutral-text-weak;
  margin-top: fn.spacing(6);

  p {
    line-height: 1.5;

    &:not(:last-child) {
      margin-bottom: fn.spacing(2);
    }
  }

  ol {
    padding-inline-start: fn.spacing(2);

    li {
      margin-bottom: fn.spacing(3);
    }
  }

  li::marker {
    font-size: 11px;
  }
}

.footer-copyright-row {
  border-top: 1px solid var.$color-neutral-border-default;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-top: fn.spacing(4); // 32px
  padding: (fn.spacing(4)) 0; // 32px
  flex-direction: column-reverse;
}

.footer-copyright {
  color: var.$color-neutral-grey-1;
}

.social-icon {
  display: block;
  position: relative;
  height: fn.spacing(3); // 24px
  width: fn.spacing(3); // 24px
}

@media screen and (min-width: var.$breakpoint-md) {
  .footer-content-container {
    padding-left: var.$medium-and-up-gutter-width;
    padding-right: var.$medium-and-up-gutter-width;
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .footer {
    padding-top: fn.spacing(8); // 64px
  }

  .footer-address {
    margin-top: fn.spacing(2); // 16px
  }

  .footer-row {
    flex-direction: row;
    justify-content: space-between;
    margin-top: fn.spacing(2); // 16px
    // The figma shows the items on the right should not butting up against the edge of the container
    padding-right: fn.spacing(4); // 32px
  }

  .footer-section:first-child {
    max-width: 30%;
  }

  .footer-section + .footer-section {
    margin-top: 0;
    margin-left: fn.spacing(2); // 16px
  }

  .footer-disclosure {
    margin-top: fn.spacing(12);
  }

  .footer-copyright-row {
    flex-direction: row;
  }

  .scroll-to-top {
    display: none;
  }
}
